import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./join.css";
import { Link } from 'react-router-dom';
import ReactMarkdown from "react-markdown";

export default function Join({ contest_details }) {
    const [access_token, setAccessToken] = useState(localStorage.getItem('verified_access_token'));
    const [loading, setLoading] = useState(false);

    const [user, setUser] = useState(null);
    const [userCredits, setUserCredits] = useState(user?.credits);

    useEffect(() => {
        (async () => {
            setLoading(true)
            console.log("getting user info");
            let access_token = localStorage.getItem('verified_access_token')
            if (localStorage.getItem('verified_access_token') === null) {
                // window.location.href = '/#/join';
                console.log("Not logged in, initial check");

            } else {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}/me`, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                })
                const { signed_in } = response.data
                if (!signed_in) {
                    // window.location.href = '/#/join';
                    console.log("Not logged in");

                }
                // http://localhost:8000/contests/a5ee289e0c924758991f1f5ead23f321/rank
                setUser(response.data.user)
                setAccessToken(access_token)
                setLoading(false)

            }

            setLoading(false)
        })()
    }, [access_token])

    const handleJoinContest = async (e) => {
        e.preventDefault();

        if (!user) {
            // at this point we can collect users email address, or ask for a unique account reference?

            alert('You must be logged in to join a contest.');
            return;
        }

        if (userCredits < contest_details?.entry_fee) {
            alert('You do not have enough credits to join this contest. Please buy more credits.');
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/join-contest/${contest_details?.id}`, {
                user_id: user.id,
                entry_fee: contest_details?.entry_fee
            }, {
                headers: {
                    'Authorization': `Token ${access_token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                alert('You have successfully joined the contest!');
                setUserCredits(userCredits - contest_details?.entry_fee);  // Deduct the entry fee from user credits
                window.location.reload()
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error joining the contest:', error);
            alert('Error joining the contest. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleJoinContest} method='post'>
            <div className='mb-6'>
                <div role="alert" className="text-left alert alert-default mb-2 border-gray-300 border-2 bg-light-yellow">
                    <div>
                        <h2 className='font-bold text-lg text-gray-500'>Join</h2>
                        <h3 className="font-bold ">{contest_details?.title}</h3>

                        <div className="text-xs mt-2 mb-2">
                            <ReactMarkdown>{contest_details?.description}</ReactMarkdown>
                        </div>

                        <div className='mt-3'>
                            <span className='p-2 bg-gray-100 text-xs rounded-xl highlight'>Entry fee: {contest_details?.entry_fee} credits</span>
                        </div>

                        <div className='mt-4'>
                            <span className='p-2 bg-gray-100 text-xs rounded-xl highlight'>
                                {new Date(contest_details?.start_time).toLocaleDateString()}
                            </span>

                            <span className='p-2 ml-2 bg-gray-100 text-xs rounded-xl highlight'>
                                {new Date(contest_details?.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })}
                                -
                                {new Date(contest_details?.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='card bg-green-200 p-4 mt-6 shadow-2xl bg-light-green'>
                <h3 className='font-bold text-lg text-gray-700'>Terms & Conditions</h3>
                <div className='scrollable-area bg-white p-3 border border-gray-300 rounded-lg mb-4' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                    <p className='text-sm text-gray-700'>
                        By joining this contest, you agree that the entry fee of {contest_details?.entry_fee} credits will be deducted from your account balance. Please ensure that you have enough credits before proceeding. If you do not have sufficient credits, you will need to purchase more credits to participate. All credits used for the entry fee are non-refundable.
                    </p>
                    <p className='text-sm text-gray-700'>
                        The contest will run from {new Date(contest_details?.start_time).toLocaleDateString()} {new Date(contest_details?.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })} to {new Date(contest_details?.end_time).toLocaleDateString()} {new Date(contest_details?.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })}. Please make sure to participate within this timeframe.
                    </p>
                </div>

                <div className='mt-4'>
                    <button type='submit' className='btn btn-sm btn-primary' disabled={loading}>{loading ? 'Loading...' : 'Join Contest'}</button>

                    <div className='float-right'>
                        <Link to={'/market'} className='btn btn-sm btn-secondary'>
                            Buy Credit
                        </Link>
                    </div>
                </div>
            </div>

            <hr className='mt-6 text-green-900' />

            <p className='mt-10 text-xs text-gray-600'>By clicking join, you agree to our <a href='/#/terms' className='text-primary'>Terms of Service</a></p>
        </form>
    );
}
