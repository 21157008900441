import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../nav';
import { motion } from 'framer-motion';
import axios from 'axios';

export default function Home() {
    // const topics = ['math', 'physics', 'driving', 'cooking', 'coding', 'history', 'music', 'art', 'sports', 'biology', 'chemistry', 'astronomy', 'literature', 'finance', 'photography', 'gardening', 'engineering', 'medicine', 'geography', 'languages']; // Expanded list of topics
    const [currentTopicIndex, setCurrentTopicIndex] = useState(0);
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [access_token, setAccessToken] = useState(localStorage.getItem('verified_access_token'))
    const [user, setUser] = useState(null)

    const [topics, setTopics] = useState([]);
    const [contests, setContests] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // const interval = setInterval(() => {
        //     setCurrentTopicIndex((prevIndex) => (prevIndex + 1) % topics.length); // Cycle through topics
        // }, 2000); // Change topic every 2 seconds

        getUser()

        // return () => clearInterval(interval); // Cleanup interval

        const fetchContests = async () => {
            try {
                const response_ = await fetch(`${process.env.REACT_APP_API_URL}/topics`);
                const data_ = await response_.json();
                setTopics(data_.data)
                let topic_id = data_.data[0].id

                const response = await fetch(`${process.env.REACT_APP_API_URL}/contests/${topic_id}`, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                })

                const data = await response.json();

                setContests(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching contests:', error);
            }
        };

        fetchContests();


    }, [topics.length]);

    const getUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/me`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            const { signed_in, user } = response.data;
            if (signed_in) {
                setIsLoggedIn(true)
                setUser(user)
            }
        } catch (error) {
            console.log("checking user failed");

        }
    }

    if (loading) {
        return (
            <>
                <Navbar />

                <div className="flex justify-center items-center h-screen">
                    <span>VerifiQ is Loading...</span>
                </div></>

        )
    }

    return (
        // <div className="flex flex-col items-center justify-center min-h-screen text-gray-50" style={{
        //     backgroundColor: 'rgb(4, 30, 15)'
        // }}>
        //     <h1 className="text-4xl font-bold mb-8">verifiQ.co.ke</h1>
        //     <p className="mb-4">Choose your language</p>
        //     <button className="btn btn-outline mb-2 text-gray-100">
        //         <span role="img" aria-label="UK Flag">🇬🇧</span> English
        //     </button>
        //     <button className="btn btn-outline mb-2 text-gray-50">
        //         <span role="img" aria-label="Swedish Flag">🇸🇪</span> Swahili
        //     </button>
        // </div>

        <div className='min-h-screen bg-base-200' style={{
            backgroundImage: 'url(/images/home-4.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
        }}>
            <Navbar />

            {!loading && (<>
                <div className="hero" style={{ minHeight: '90vh' }}>
                    <div className="hero-content">
                        <div className="max-w-md">
                            {/* <img src={'/images/logo.png'} alt='verifiq'></img>
                        <br></br> */}
                            <br></br>
                            <h1 className="text-lg text-white font-bold">{contests[0]?.title}</h1>
                            <br></br>
                            {/* <motion.p
                            className="py-6"
                            initial={{ opacity: 0 }} // Initial opacity
                            animate={{ opacity: 1 }} // Animate to full opacity
                            transition={{ duration: 0.5 }} // Animation duration
                        > */}
                            {/* How good are you at <span className="text-2xl font-bold text-gray-800"></span>? */}
                            {/* <br></br>
                            <span className='text-sm text-gray-700'>Join a live contest and find out!</span> */}
                            {/* </motion.p> */}

                            <span className="inline-block bg-gray-200 text-gray-500 px-2 py-1 text-xs font-semibold rounded-lg">
                                {new Date(contests[0]?.start_time).toLocaleDateString()}
                                &nbsp;
                                ({new Date(contests[0]?.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })})
                            </span>


                            &nbsp;
                            &nbsp;

                            <Link className="btn btn-warning btn-sm" title='Join an active contest' to={`/topics/${topics[0]?.id}/contests/${contests[0]?.id}`}>Join Now &#x1F60B;</Link>

                            {(user && ['mxmandela@gmail.com', 'omondi.fidel42@gmail.com'].includes(user.email)) && (
                                <Link to="/contests/create" className="btn btn-primary btn-sm ml-2" title='Create your own contest for others to join, then you can add as many contests'>Create a contest </Link>
                            )}

                            <br></br>

                            <div className='mt-6 text-xs text-gray-200'>
                                <div>No account?</div>
                                <br></br>
                                {!isLoggedIn && (
                                    <><Link to={'/join'} className='link'>Sign up</Link>
                                        &nbsp;
                                        &nbsp;
                                    </>
                                )}
                                <Link to={'/terms'} className='link'>Terms & Conditions</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>)}
        </div >
    );
}
