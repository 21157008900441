import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../nav';
import Loader from '../../loader';


const CreateContest = () => {
    const [access_token, setAccessToken] = useState(localStorage.getItem('verified_access_token'))
    const [loading, setLoading] = useState(false);

    // Define state variables to store form data
    const [formData, setFormData] = useState({
        "topic_id": 1,
        "title": "Soccer Trivia Quiz",
        "description": "Test your knowledge about soccer with this trivia quiz!",
        "start_time": new Date(),
        "duration_minutes": 5,
        "entry_fee": 25
    });


    const [topics, setTopics] = useState([]);
    const [isSearchingVisible, setIsSearchingVisible] = useState(false);

    const [fetchingTopics, setFetchingTopics] = useState(true);

    useEffect(() => {
        fetchTopics();
    }, []);

    const fetchTopics = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/topics`);
            const data = await response.json();
            setTopics(data.data);
            setFetchingTopics(false);
        } catch (error) {
            console.error('Error fetching contests:', error);
        }
    };



    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/contests/${formData.topic_id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            });

            if (response.data.success) {
                alert('Contest created successfully!');
                window.location.href = '/#/topics';
            } else {
                alert('Failed to create contest. Please try again later.');
            }

            setLoading(false);
        } catch (error) {
            console.error('Error creating contest:', error);
            setLoading(false);
        }
    };

    // Function to handle form field changes
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };


    return (
        <div>
            <Navbar />

            {
                fetchingTopics ? (<Loader />) : (
                    <div className="p-4">
                        <h2 className='text-xl mb-4 font-bold'>Create Contest</h2>






                        <div className='p-4  alert-warning mb-2 bg-yellow-200 text-xs rounded-lg mb-4'>
                            Questions will be automatically created based on the description. They will be 15 questions.
                            A winner is supposed to score at aleast 9 correct
                        </div>

                        <form onSubmit={handleSubmit} className=''>
                            <div className='form-group card bg-yellow-100 rounded-lg p-4 mb-3'>
                                <label className='text-sm font-semibold'>Select topic:</label>
                                <select name="topic_id" className='bg-gray-100 text-gray-700 input input-sm w-full' value={formData.topic_id} onChange={handleChange}>
                                    {topics.length && topics.map((topic, index) => (
                                        <option key={index} value={topic.id}>{topic.name}</option>
                                    ))}
                                </select>


                                <div className='mt-3'>
                                    <label className='text-sm font-semibold'>Title: </label>
                                    <input type="text" name="title" className='bg-gray-100 text-gray-700  input input-sm w-full' value={formData.title} onChange={handleChange} />
                                </div>
                            </div>

                            <div className='form-group mt-4 mb-4 card bg-green-300 rounded-lg p-4'>
                                <div className='form-group mt-4'>
                                    <label className="text-sm font-semibold">Description(be very descriptive): </label>
                                    <textarea name="description" className='textarea w-full' value={formData.description} onChange={handleChange} />
                                </div>

                                <div className='form-group mt-4'>
                                    <label className='text-sm pr-2 font-semibold'>Start time: </label>
                                    <input type="datetime-local" className='input input-sm w-60 float-right' name="start_time" value={formData.start_time} onChange={handleChange} />
                                </div>

                                <div className='form-group mt-4'>
                                    <label className='text-sm pr-2 font-semibold'>Duration (in minutes): </label>
                                    <input type="number" className='input input-sm w-40  float-right' name="duration_minutes" value={formData.duration_minutes} onChange={handleChange} />
                                </div>

                                <div className='form-group mt-4'>
                                    <label className='text-sm pr-2 font-semibold'>Entry Credits(min 25): </label>
                                    <input type="number" className='input input-sm w-full' name="entry_fee" value={formData.entry_fee} onChange={handleChange} />
                                </div>
                            </div>

                            <button type="submit" disabled={loading} className='mt-4 btn btn-primary'>{loading ? 'Creating...' : 'Create Contest'}</button>
                        </form>
                    </div>
                )
            }
        </div >
    );
};

export default CreateContest;
